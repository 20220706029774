import React, { useState } from "react";
import { Navbar, Nav, Row, Col, Modal } from "react-bootstrap";
import "./Navbar.scss";

import { connect } from "react-redux";
import { signOut } from "../../actions/auth";
import { isNotACampus, isPMCGEnv, isEQUATORIALEnv } from "../../utils/EnvUtils";

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

const Navigator = ({ user, signOut }) => {
  const loginButton = () => {
    const { isSignedIn } = user;
    if (isSignedIn) return <Nav.Link onClick={signOut}>LOGOUT</Nav.Link>;
    return <Nav.Link href="/login">LOGIN</Nav.Link>;
  };

  const [showPDFModal, setShowPDFModal] = useState(false);
  const pdfURL =
    "https://drive.google.com/file/d/1ghEB_hcgNVKR9DUftDJDwaxCSbDWKbmb/preview";

  const getENVClassName = () => {
    const env = process.env.REACT_APP_CAMPUS.toLowerCase();
    if (isNotACampus) return "liteme-maps";
    return env;
  };

  const isDarkNavbar = () => {
    return getENVClassName() === "litecampus-alt";
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant={isDarkNavbar() ? "dark" : "light"}
        className={"nav " + getENVClassName()}
      >
        <Navbar.Brand
          href="/"
          className={"logo " + getENVClassName()}
        ></Navbar.Brand>
        {isPMCGEnv && (
          <Navbar.Brand href="/" className="logo pmcg-alt"></Navbar.Brand>
        )}
        {isEQUATORIALEnv && (
          <Navbar.Brand href="/" className="logo equatorial"></Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Row>
            <Col xs={8}>
              <Nav>
                <Nav.Link href="/">VOLTAR</Nav.Link>
                <Nav.Link onClick={() => setShowPDFModal(true)}>
                  COMO USAR
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs={4} className="login-button">
              <Nav>{loginButton()}</Nav>
            </Col>
          </Row>
        </Navbar.Collapse>
      </Navbar>

      {/* Modal para exibir o PDF */}
      <Modal
        show={showPDFModal}
        onHide={() => setShowPDFModal(false)}
        size="lg"
        centered
      >
        <Modal.Body style={{ padding: 0 }}>
          <iframe
            src={pdfURL}
            width="100%"
            height="600px"
            style={{ border: "none" }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, { signOut })(Navigator);
