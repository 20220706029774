import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ConsumptionChart from "../../../components/Chart/ConsumptionChart";
import DemandWidget from "../../../components/DemandWidget/DemandWidget";
import CompareConsumptionWidget from "../../../components/CompareConsumptionWidget/CompareConsumptionWidget";
import ConsumptionWidget from "../../../components/ConsumptionWidget/ConsumptionWidget";
import AreaList from "../../../components/AreaList/AreaList";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import TimeTypeSelector from "../../../components/TimeTypeSelector/TimeTypeSelector";
import EnergyMoreInfo from "../../../assets/img/more-details.svg";
import { postTracking, postWithoutAuthTracking } from "../../../api/Tracking";
import moment from "moment";

import "../../Dashboard.scss";
import "./Energy.scss";
import EnergyNav from "./EnergyNav";

const validateTime = data => (data ? data.end : new Date().getTime());

const EnergyWidgets = ({
  area,
  date,
  admin,
  setDate,
  showAll,
  timeType,
  demandData,
  onItemClick,
  allAreasData,
  consumptionData,
  statusData,
  onShowAllClick,
  setTimeType,
  onTitleClick,
  areas,
  building,
  setShowDetailed
}) => {
  const [filteredAreas, setFilteredAreas] = useState(areas);

  useEffect(() => {
    setFilteredAreas(
      areas.sort((x, y) =>
        timeType === "today"
          ? y?.totalConsumption?.day - x?.totalConsumption?.day
          : y?.totalConsumption?.month - x?.totalConsumption?.month
      )
    );
  }, [areas]);

  useEffect(() => {
    const authData = localStorage.getItem("AuthData");
    const authToken = authData
      ? encodeURIComponent(authData)
      : localStorage.getItem("AuthToken")
      ? encodeURIComponent(localStorage.getItem("AuthToken"))
      : null;

    if (!authToken) {
      if (area && area.name && area.buildingId)
        postWithoutAuthTracking(
          "MAPS-BUILDING-CLICK: " + area.name,
          area.buildingId
        );
    } else {
      if (area && area.name) postTracking("MAPS-BUILDING-CLICK: " + area.name);
    }
  }, [area]);

  const getTime = () => {
    if (timeType === "today") {
      return "00h - " + moment().hour() + "h" + moment().minute();
    } else {
      return moment(date).format("MMMM");
    }
  };

  const authToken = localStorage.getItem("AuthData")
    ? encodeURIComponent(localStorage.getItem("AuthData"))
    : encodeURIComponent(localStorage.getItem("AuthToken"));

  const { userEmail } = JSON.parse(localStorage.getItem("user")) || {};

  return (
    <>
      <section className="header">
        <div className="header-column">
          <AreaHeader
            areaName={area.name}
            areaUpperName={process.env.REACT_APP_CAMPUS}
            onUpperNameClick={onShowAllClick}
            showAll={showAll}
            statusData={statusData}
            typeMeter="medição"
            tab="energy"
            date={date}
            setDate={setDate}
            showBackButton={allAreasData?.areas?.length > 1}
          />
        </div>

        <div className="timetype-column">
          <TimeTypeSelector
            timeType={timeType}
            onTimeTypeChange={setTimeType}
          />
        </div>

        <div className="link-to-liteme-wrapper">
          {!showAll && admin && (
            <Button
              id="link-to-liteme"
              className={"link-to-liteme"}
              title="Para mais informações acesse o LiteMe"
              {...(admin && area.buildingId
                ? {
                    href: `${process.env.REACT_APP_ENV}#/login/${area.buildingId}/home/${authToken}?email=${userEmail}`,
                    variant: "link"
                  }
                : {})}
            >
              <img className={"more-info-btn"} src={EnergyMoreInfo} />
            </Button>
          )}
        </div>
      </section>
      {showAll && <EnergyNav setShowDetailed={setShowDetailed} />}
      <section style={{}} className="components">
        {!showAll && (
          <div className="widget">
            <DemandWidget
              demand={Number(building?.demand)}
              time={validateTime(demandData)}
              type="energy"
            ></DemandWidget>
          </div>
        )}
        {showAll && (
          <div className="widget">
            <DemandWidget
              demand={allAreasData ? allAreasData.demand : 0}
              time={validateTime(demandData)}
              type="energy"
            ></DemandWidget>
          </div>
        )}
        {!showAll && timeType === "today" && (
          <div className="widget compare-widget">
            <CompareConsumptionWidget
              type="energy"
              timeType={timeType}
              daily={building?.compare?.day?.actual}
              average={building?.compare?.day?.average}
              lastMeasurement={
                building?.dayConsumption
                  ? building.dayConsumption[
                      building?.dayConsumption?.length - 1
                    ]
                  : [0, 0]
              }
              hour={building?.compare?.day?.hour}
            ></CompareConsumptionWidget>
          </div>
        )}

        {!showAll && timeType !== "today" && (
          <div className="widget compare-widget">
            <CompareConsumptionWidget
              type="energy"
              timeType={timeType}
              daily={building?.compare?.month?.actual}
              average={building?.compare?.month?.average}
              lastMeasurement={
                building?.monthConsumption
                  ? building.monthConsumption[
                      building?.monthConsumption?.length - 1
                    ]
                  : [0, 0]
              }
              hour={building?.compare?.month?.hour}
            ></CompareConsumptionWidget>
          </div>
        )}
        {!showAll && (
          <div className="widget">
            <ConsumptionWidget
              consumption={
                timeType === "today"
                  ? consumptionData?.day
                  : consumptionData?.month
              }
              timeType={timeType}
              type="energy"
              date={date}
            ></ConsumptionWidget>
          </div>
        )}
        {showAll && (
          <div className="widget">
            <ConsumptionWidget
              consumption={
                timeType === "today"
                  ? allAreasData.consumption.day
                  : allAreasData.consumption.month
              }
              timeType={timeType}
              type="energy"
              date={date}
            ></ConsumptionWidget>
          </div>
        )}
        {showAll && (
          <div className="area-col">
            <AreaList
              type="energy"
              title="Consumo por pontos de medição"
              subtitle={getTime()}
              timeType={timeType}
              onItemClick={onItemClick}
              onTitleClick={onTitleClick}
              fontType="energy"
              consumptionDay={allAreasData.consumption.day}
              consumptionMonth={allAreasData.consumption.month}
            >
              {filteredAreas &&
                filteredAreas.map(area => (
                  <section
                    className="table-row"
                    key={area.buildingId}
                    name={area.name}
                  >
                    <div className="list-item-consumption">
                      <div className="list-item-name">{area.name}</div>
                    </div>
                    <div className="list-item-consumption">
                      <div className="list-item-value">
                        {timeType === "today"
                          ? area?.totalConsumption?.day.toFixed(2)
                          : area?.totalConsumption?.month.toFixed(2)}
                      </div>
                    </div>
                  </section>
                ))}
            </AreaList>
          </div>
        )}
      </section>
      <section className="dashboard-chart">
        {showAll && timeType === "today" && (
          <ConsumptionChart
            type="energy"
            data={allAreasData.chart.day}
            timeType={timeType}
            date={date}
          ></ConsumptionChart>
        )}
        {!showAll && timeType === "today" && (
          <ConsumptionChart
            type="energy"
            data={building?.dayConsumption}
            timeType={timeType}
            date={date}
          ></ConsumptionChart>
        )}
        {showAll && timeType !== "today" && (
          <ConsumptionChart
            type="energy"
            data={allAreasData.chart.month}
            timeType={timeType}
            date={date}
          ></ConsumptionChart>
        )}
        {!showAll && timeType !== "today" && (
          <ConsumptionChart
            type="energy"
            data={building?.monthConsumption}
            timeType={timeType}
            date={date}
          ></ConsumptionChart>
        )}
      </section>
    </>
  );
};

export default EnergyWidgets;
