import { Button } from "react-bootstrap";
import React from "react";

const EnergyNav = ({ showDetailed, setShowDetailed }) => {
  const handleButtonClick = isDetailed => {
    setShowDetailed(isDetailed);
  };

  return (
    <section>
      <Button
        className={`energy-nav-button energy-nav-button-vision ${
          !showDetailed ? "active" : ""
        }`}
        onClick={() => handleButtonClick(false)}
      >
        Visão Geral
      </Button>
      <Button
        className={`energy-nav-button energy-nav-button-all ${
          showDetailed ? "active" : ""
        }`}
        onClick={() => handleButtonClick(true)}
      >
        Todos os pontos PMCG
      </Button>
    </section>
  );
};

export default EnergyNav;
